$(document).ready(function() {

   if ($("select#user_branch").val() == "") {
    $("select#team_id option").remove();
    var row = "<option value=\"" + "" + "\">" + "-" + "</option>";
    $(row).appendTo("select#team_id");
   }
   $("select#user_branch").change(function(event) {
      let $teamSelect = $("select#team_id"),
      $self = $(event.currentTarget);
      unblocks($teamSelect.parent(), $self.parent())

    var id_value_string = $(this).val();
    if (id_value_string == "") {
     $("select#team_id option").remove();
     var row = "<option value=\"" + "" + "\">" + "-" + "</option>";
     $(row).appendTo("select#team_id");
    } else {
      blocks($teamSelect.parent(), $self.parent())
     // Send the request and update course dropdown
     $.ajax({
      dataType: "json",
      cache: false,
      url: '/users/get_teams_by_branch/' + id_value_string,
      error: function(XMLHttpRequest, errorTextStatus, error) {
       alert("Failed to submit : " + errorTextStatus + " ;" + error);
       unblocks($teamSelect.parent(), $self.parent())
      },
      success: function(data) {
       // Clear all options from course select
       $("select#team_id option").remove();
       // Fill team_id select
        row = "<option value=\"-\">-</option>";
        $(row).appendTo("select#team_id");
       $.each(data, function(i, j) {
        row = "<option value=\"" + j.team_id + "\">" + j.team_name + "</option>";
        $(row).appendTo("select#team_id");
       });
       unblocks($teamSelect.parent(), $self.parent())
      }
     });
    }
   });

});
