// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("alpinejs");
require("bootstrap-datepicker");

global.toastr = require('toastr');

import "../stylesheets/application";
import "./bootstrap_custom.js";
import "./filter_supervisor_by_branch.js";
import "./filter_sale_in_prospect_by_branch.js";
import "./filter_team_dropdown_by_branch.js";
import "./progress_step.js";
import "./sorttable.js";
import "./utils.js";

$.fn.datepicker.defaults.format = "yyyy-mm-dd";

//= require jquery
//= require jquery_ujs
//= require jquery.turbolinks
//= require bootstrap-sprockets
//= require turbolinks
//= require_tree .

// document.addEventListener("DOMContentLoaded", mountOnLoad);
// document.addEventListener("turbolinks:load", mountOnLoad);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.jQuery = $;
window.$ = $;
