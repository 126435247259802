$(document).ready(function() {
  if ($("select#team_branch").val() == "") {
    count_supervisor = $("h5#count_supervisor");
    count_supervisor.text("- Supervisor available");
    count_sale = $("h5#count_sale");
    count_sale.text("- Sales available");
  }

  $("#team_name").change(function() {
    value = $(this).val();
    $(".team_name_text").text(value);
  });

  $("#target").change(function() {
    value = $(this).val();
    $(".target_text").text(value);
  });

  $("#team_description").change(function() {
    value = $(this).val();
    $(".team_description_text").text(value);
  });

  $("select#team_branch").change(function() {
    count_supervisor = $("h5#count_supervisor");
    count_supervisor.text("- Supervisor available");
    count_sale = $("h5#count_sale");
    count_sale.text("- Sales available");
    var id_value_string = $(this).val();
    var value_string = this.options[this.selectedIndex].text;
    if (id_value_string == "") {
      $("tbody#supervisor_users tr").remove();
      $("tbody#sale_users tr").remove();
    } else {
      // Send the request and update course dropdown
      $.ajax({
        dataType: "json",
        cache: false,
        url: '/teams/get_supervisors_by_branch/' + id_value_string,
        error: function(XMLHttpRequest, errorTextStatus, error) {
          alert("Failed to submit : " + errorTextStatus + " ;" + error);
        },
        success: function(data) {
          $(".branch_location_text").text(value_string);
          count_supervisor.text(data.length + " Supervisor available")
          // Clear all options from course select
          $("tbody#supervisor_users tr").remove();
          // Fill team_id select
          $.each(data, function(i, j) {
            full_name = j.full_name != null ? j.full_name : "-"
            team_name = j.team_name != null ? j.team_name : "-"
            row = "<tr>\
            <td style=\"vertical-align: unset;\">\
              <input type=\"hidden\" id=\"supervisor_index_" + i + "\" value=\"" + full_name + "\" >\
              <input type=\"radio\" Onclick=\"changeValue(" + i + ")\" name=\"supervisor_user_id\" required=\"true\" value=\"" + j.user_id + "\" />\
            </td>\
            <td>" + team_name + "</td>\
            <td>" + full_name + "</td>\
            <td>" + j.employee_id + "</td>\
            <td>" + j.position_name + "</td>\
            <td>" + j.branch_name_en + "</td>\
            </tr>";

            $(row).appendTo("tbody#supervisor_users");
          });
        }
      });

      $.ajax({
        dataType: "json",
        cache: false,
        url: '/teams/get_sales_by_branch/' + id_value_string,
        error: function(XMLHttpRequest, errorTextStatus, error) {
          alert("Failed to submit : " + errorTextStatus + " ;" + error);
        },
        success: function(data) {
          $(".branch_location_text").text(value_string);
          count_sale.text(data.length + " Sale available")
          // Clear all options from course select
          $("tbody#sale_users tr").remove();
          // Fill team_id select
          $.each(data, function(i, j) {
            full_name = j.full_name != null ? j.full_name : "-"
            row = "<tr>\
            <td style=\"vertical-align: unset;\">\
              <input type=\"hidden\" id=\"sale_index_" + i + "\" value=\"" + full_name + "\" >\
              <input type=\"checkbox\" name=\"sale_user_ids[]\" value=\"" + j.user_id + "\" />\
            </td>\
            <td>" + full_name + "</td>\
            <td>" + j.employee_id + "</td>\
            <td>" + j.position_name + "</td>\
            <td>" + j.branch_name_en + "</td>\
            </tr>";

            $(row).appendTo("tbody#sale_users");
          });
        }
      });
    }
  });

});
