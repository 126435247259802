$(document).ready(function() {

  count = 0;
  $('input[type="checkbox"]').click(function(){
    selected_count = $("h5#selected_prospect_count")
    prospect = $("prospect_index_" + $(this).val())
    if($(this).is(":checked")){
      count = count+1
      selected_count.text("Assign "+ count +" prospects to:")
    }
    else if($(this).is(":not(:checked)")){
      count = count-1
      selected_count.text("Assign "+ count +" prospects to:")
    }
  });

  $("select#prospect_branch").change(function() {
    var id_value_string = $(this).val();
    if (id_value_string == "") {
      $("tbody#supervisor_users tr").remove();
      $("tbody#prospect_sale_users tr").remove();
    } else {
      $.ajax({
        dataType: "json",
        cache: false,
        url: '/teams/get_sales_by_branch/' + id_value_string,
        error: function(XMLHttpRequest, errorTextStatus, error) {
          alert("Failed to submit : " + errorTextStatus + " ;" + error);
        },
        success: function(data) {
          // Clear all options
          $("tbody#prospect_sale_users tr").remove();
          // Fill team_id select
          $.each(data, function(i, j) {
            full_name = j.full_name != null ? j.full_name : "-"
            row = "<tr>\
            <td style=\"vertical-align: unset;\">\
            <input type=\"hidden\" id=\"sale_index_" + i + "\" value=\"" + full_name + "\" >\
            <input type=\"radio\" name=\"sale_user_id\" value=\"" + j.user_id + "\" />\
            </td>\
            <td>" + full_name + "</td>\
            <td>" + j.employee_id + "</td>\
            <td>" + j.position_name + "</td>\
            <td>" + j.branch_name_en + "</td>\
            </tr>";

            $(row).appendTo("tbody#prospect_sale_users");
          });
        }
      });
    }
  });

});
